import { Viewer, Worker } from '@react-pdf-viewer/core';
import React, { useRef } from 'react';
import { createRoot } from 'react-dom/client';
import '@react-pdf-viewer/core/lib/styles/index.css';

export default function PDFViewer(){
    const fileData= document.getElementById('pspdfkit').dataset;
    const pdfURL = `https://olxforexbucket.s3.amazonaws.com/uploads/files/${fileData.file}`;
return (
 <div>
<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
<Viewer fileUrl={pdfURL} />
</Worker>
 </div>
 );
};

if(document.getElementById('pspdfkit')){
    createRoot(document.getElementById('pspdfkit')).render(<PDFViewer />)
}

