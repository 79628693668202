import React, { Component } from 'react'

export default class Row extends Component {
  render() {
    return (
      <tr className="align-middle">
        <td className='d-none'>
        <audio id="myAudio" autoPlay>
        <source src={this.props.tone} type="audio/mpeg"/>
        <source src={this.props.oog} type="audio/ogg"/>
        </audio>
        </td>
                <td scope="row">{(this.props.counter + 1)}</td>
                <td>{this.props.data.type}</td>
                <td ><span className='p-1 rounded-3'>{this.props.data.status} - {this.props.data.id}</span></td>
                <td ><span className='p-1 rounded-3'>{this.props.data.date}</span></td>
                <td><a onClick={()=>{this.props.delete(this.props.data.chat_id)}}  className="btn btn-dark">Enter Conversation</a></td>
      </tr>
    )
  }
}
