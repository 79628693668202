import React, { Component, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import Tone from './assets/message.mp3';
import ToneOog from './assets/message_tone.ogg';
import Row from './Row';
import axios from 'axios';

export default class Support extends Component {
    constructor(){
        super();
        this.state={
            chats:null,
        }
    }

    componentDidMount() {
        
        
        window.Echo.channel('expert-leaves-785')
        .listen('SupportBroadcast', event => {
            console.log(event.data.category);
            if(event.data.category == document.getElementById('supportcontentpanel').dataset.category){
                let tblRow = [...this.state.chats];
                tblRow.push(event.data);
                this.setState({chats:tblRow});
            }
            
        });

        window.Echo.channel('expert-leaves-785')
        .listen('CloseChatBroadcast', event => {
            this.featch();
        });
        this.featch();        
  }

  featch = ()=>{
    const config = {
        headers:{
        'x-api-key': "cPAfc4KfPJF5gEQbbWjpbA==WuNCJX9q02Uu7Sz8",
        'Content-Type': 'application/json',  
    },
    data: {}
    }
    setTimeout(() => {
        axios.get(`${location.origin}/panel/get/chats/${document.getElementById('supportcontentpanel').dataset.category}`,config,
    ).then((data)=>{
        let chatdata = data.data.data;
        this.setState({chats:chatdata});
    });
    }, 500);  
  }

    delRow = (id)=>{
        window.open(location.origin+'/panel/live/chat/'+document.getElementById('supportcontentpanel').dataset.category+'/conversation/'+id,'_blank');
        let tblrows = [...this.state.chats];
        tblrows = tblrows.filter((row)=>row.chat_id !== id);
        this.setState({chats:tblrows});
    }

  render() {
    return <>
    
    {
        
        this.state.chats&&this.state.chats.length>0 ?
        <div className='table-responsive'>
    <table className="table table-striped">
    <thead className="thead-inverse">
        <tr>
            <th>#</th>
            <th>type</th>
            <th>status</th>
            <th>date</th>
            <th>handel</th>
        </tr>
        </thead>
        <tbody>
        {this.state.chats&&this.state.chats.map((chat,i)=>  <Row oog={ToneOog} tone={Tone} key={i} counter={i} delete={this.delRow} data={chat}/>)}
    </tbody>
    </table>
    </div>
    :
    <div className="bg-primary text-center p-5 text-white text-capitalize alert">
            soory, there are No data for view!
        </div>
    }
    
    </>
  }
}

setTimeout(() => {
    if (document.getElementById('supportcontentpanel')) {
        createRoot(document.getElementById('supportcontentpanel')).render(<StrictMode>
            <Support />
          </StrictMode>);
    }
}, 1000);
