import React, { Component } from 'react'
import { createRoot } from 'react-dom/client'

export default class Chat extends Component {

    constructor(){
        super();
        this.state = {el: document.querySelector('#olx-chat .conversation'),meessages:{},}
    }

    componentDidMount() {
      window.Echo.channel('expert-leaves-785')
      .listen('CloseChatBroadcast', event => {
          if (event.data.chat_id == localStorage.getItem('chat_id')) {
            document.querySelector('.sender').classList.replace('d-block','d-none');
            document.querySelector('#chat-rate').classList.replace('d-none','d-block');
            localStorage.removeItem('chat_id');
          }
      });

      window.Echo.channel('expert-leaves-785')
      .listen('BrodcastChatWait', event => {
        if (document.querySelector('html').getAttribute('lang') === 'ar') {
          event.data.message = `امامك فى الانتظار عدد ${event.data.message}`; 
        }else{
          event.data.message = `Your turn to wait after ${event.data.message}`; 
        }
          this.setState({meessages:event.data});
          this.message(this.state.el,event.data);
      });

      window.Echo.channel('expert-leaves-785')
      .listen('ChatBroadcast', event => {
          this.setState({meessages:event.data});
          this.message(this.state.el,event.data);
      });
  }

  chatReplacer= (text)=>{
    const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
    return text.replace(linkRegex,this.replacer).replace(`/[^a-zA-Z0-9\u0621-\u064A]/g`,"");
}

replacer = (matched)=> {
  let withProtocol = matched;
    
  if(!withProtocol.startsWith("http")) {
    withProtocol = "http://" + matched;
  }
 
  const newStr = `<br/><a
    class="text-link fs-6 text-wrap"
    href="${withProtocol}"
    target="_blank"
  >
    ${matched}
  </a>`;
  
  return newStr;
  }

  message = (el,data)=> {
    let chatId = localStorage.getItem('chat_id');
    if (data.chat_id.chat_id === chatId) {
      if (data.message !== null) {
        let agent = data.chat_id.agentName !== null ? data.chat_id.agentName : 'support';
        
        let conetnt =  `<p class="overflow-hidden ${data.from == 'agent' ? 'bg-secondary float-end':'bg-danger float-start'} bg-opacity-10 rounded-2 p-2 my-2 w-75 ">
        <messageHead class='d-flex align-items-center fw-bold border-bottom mb-2'>
                ${data.from == 'agent' ?`<i class="fa-solid fa-headset fa-lg"></i> <span class="ms-2">${agent}</span>`:'<i class="fa-solid fa-user fa-lg"></i> <span class="ms-2">Me</span>'}
                </messageHead>
        ${data.attachment !== null ?'<img src="'+data.attachment+'" class="d-block w-100"/>':''}
        ${this.chatReplacer(data.message)}
        <small class='d-block border-1 text-secondary mt-1 border-top'>${data.time}</small>
      </p>`;
        el.insertAdjacentHTML('beforeend',conetnt)
        document.querySelector("#olx-chat #coversation-box").scrollTop = document.querySelector("#olx-chat #coversation-box").scrollHeight;
      }
    }
}

  render() {
    return <></>
  }
}


if (document.getElementById('olx-chat')) {
    createRoot(document.querySelector('#olx-chat .conversation')).render(<Chat />)
}