import React, { Component, StrictMode } from 'react'
import { createRoot } from 'react-dom/client';

export default class Panelchat extends Component {
    constructor(){
        super();
        this.state = {el: document.querySelector('#conversation'),meessages:{},}
    }

    componentDidMount() {
      window.Echo.channel('expert-leaves-785')
      .listen('CloseChatBroadcast', event => {
          if (event.data.chat_id == this.state.el.dataset.chat) {
            location.reload();
          }
      });

        window.Echo.channel('expert-leaves-785')
        .listen('ChatBroadcast', event => {
            this.setState({meessages:event.data});
            this.message(this.state.el,event.data);
        });
  }

  chatReplacer= (text)=>{
    const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
    return text.replace(linkRegex,this.replacer).replace(`/[^a-zA-Z0-9\u0621-\u064A]/g`,"");
}

replacer = (matched)=> {
  let withProtocol = matched;
    
  if(!withProtocol.startsWith("http")) {
    withProtocol = "http://" + matched;
  }
 
  const newStr = `<br/><a
    class="text-link fs-6 text-wrap"
    href="${withProtocol}"
    target="_blank"
  >
    ${matched}
  </a>`;
  
  return newStr;
  }

  message = (el,data)=> {
    let chatId = el.dataset.chat;
    if (data.chat_id.chat_id === chatId) {
      if (data.message !== null) {
        let user = data.chat_id.user !== null ? data.chat_id.user : 'User';
        let conetnt =  `<p class="overflow-hidden ${data.from == 'agent' ? 'bg-secondary float-end':'bg-danger float-start'} bg-opacity-10 rounded-2 p-2 my-2 w-75 ">
        <messageHead class='d-flex align-items-center fw-bold border-bottom mb-2'>
                ${data.from == 'agent' ?`<i class="fa-solid fa-headset fa-lg"></i> <span class="ms-2">Me</span>`:`<i class="fa-solid fa-user fa-lg"></i> <span class="ms-2">${user}</span>`}
        </messageHead>
        ${data.attachment !== null ?'<img src="'+data.attachment+'" class="d-block w-100"/>':''}
        ${this.chatReplacer(data.message)}
        <small class='d-block border-1 text-secondary mt-1 border-top'>${data.time}</small>
      </p>`;
        el.insertAdjacentHTML('beforeend',conetnt)
        document.querySelector("#conversation").scrollTop = document.querySelector("#conversation").scrollHeight;
        document.querySelector("#coversation").scrollTop = document.querySelector("#coversation").scrollHeight;
      }
    }
}
  render() {
    return <>
    </>
  }
  
}

if (document.getElementById('conversation')) {
    createRoot(document.getElementById('conversation')).render(<StrictMode><Panelchat /></StrictMode>);
}