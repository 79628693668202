import React, { Component } from 'react'

export default class Notifications extends Component {
  
  constructor(){
    super();
    this.state = {el: document.getElementById('table-data'),meessages:{},}
  }
  
  componentDidMount() {
        window.Echo.channel('expert-leaves-785')
        .listen('PushNotification', event => {
            this.setState({meessages:event.data});
            this.tbl(this.state.el,event.data);
        });
  }

     tbl = (el,data)=> {
        if (data.message !== null) {
          let conetnt = `<tr>
                            <td>${data.created_at}</td>
                            <td>${data.message}</td>
                          </tr>`;
          el.insertAdjacentHTML('beforeend',conetnt)
        }
    }  

  render() {

    let {created_at,message} = this.state.meessages;
    return (
      <div>{message}</div>
    )
  }
}
